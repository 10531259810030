<template lang="pug">
footer
	.top
		router-link.logo(:to='{ name: "Home" }' v-on:click.native="scroll()")
			img(src='@/assets/img/logo.svg', alt='Logo')
		.navigation-bar
			router-link(:to='{ name: "Home" }' v-on:click.native="scroll('forex')") Forex
			router-link(:to='{ name: "Home" }' v-on:click.native="scroll('programs')") Πρόγραμμα
			router-link(:to='{ name: "About" }') Ποιοι είμαστε
			router-link(:to='{ name: "FAQ" }') Συχνές ερωτήσεις
			router-link(:to='{ name: "Home" }' v-on:click.native="scroll('contact')") Επικοινωνία
	.disclaimer
		p ΠΛΗΡΗΣ ΓΝΩΣΤΟΠΟΙΗΣΗ ΚΙΝΔΥΝΟΥ: Η διαπραγμάτευση περιέχει σημαντικό κίνδυνο και δεν είναι για κάθε επενδυτή. Ένας επενδυτής θα μπορούσε ενδεχομένως να χάσει όλη ή περισσότερη από την αρχική επένδυση. Το επιχειρηματικό κεφάλαιο είναι χρήματα που μπορούν να χαθούν χωρίς να διακυβευθεί η οικονομική ασφάλεια ή ο τρόπος ζωής. Μόνο τα επιχειρηματικά κεφάλαια θα πρέπει να χρησιμοποιούνται για διαπραγμάτευση και μόνο εκείνα με επαρκές επιχειρηματικό κεφάλαιο θα πρέπει να εξετάζουν τις συναλλαγές. Οι προηγούμενες αποδόσεις δεν διασφαλίζουν τις μελλοντικές. 
	.copyrights
		p Copyright © 2020-#{new Date().getFullYear()} Athens FX Academy

</template>

<script>
export default {
	methods: {
		scroll(id) {
			if (id) {
				const y =
					document.getElementById(id).getBoundingClientRect().top +
					window.scrollY
				window.scroll({
					top: y - 120,
					behavior: 'smooth'
				})
			} else {
				document.body.scrollIntoView({ behavior: 'smooth' })
			}
		}
	}
}
</script>

<style lang="scss" scoped>
footer {
	background-color: var(--dark-blue);
	display: flex;
	flex-direction: column;
	padding: 15px 10%;
	font-weight: 600;
	font-size: 18px;
	color: var(--white);
	margin-top: auto;

	@media only screen and (max-width: 1150px) {
		& {
			padding: 20px 40px;

			.top {
				align-items: center;
				flex-direction: column;

				.logo {
					margin-right: unset !important;
				}

				.navigation-bar {
					flex-direction: column;
				}
			}
		}
	}

	> * {
		margin-top: 20px;
	}

	.top {
		display: flex;
		justify-items: center;

		.logo {
			display: flex;
			margin-right: auto;

			> * {
				height: 100%;
			}
		}

		.navigation-bar {
			display: flex;
			margin-top: 5px;
			text-align: center;
			margin-right: -10px;

			> * {
				margin-right: 10px;
			}

			a {
				color: inherit;
				text-decoration: none;
				white-space: nowrap;
				padding: 5px 0;
			}
		}
	}

	.disclaimer {
		font-size: 14px;
	}

	.copyrights {
		text-align: center;
		font-weight: 400;
		font-size: 12px;
	}
}
</style>
