<template lang="pug">
	#app
		#loader
			Lottie(path="animations/loader.json" :loop="false" @AnimControl="setAnimController")
		Header
		router-view
		Footer
</template>

<script>
import Lottie from 'lottie-vuejs/src/LottieAnimation.vue'

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
	components: {
		Lottie,
		Header,
		Footer
	},
	methods: {
		setAnimController(anim) {
			anim.onComplete = () => {
				document.querySelector('#app > #loader').remove()
			}
		}
	}
}
</script>

<style lang="scss">
#app {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	> #loader {
		background-color: white;
		z-index: 1000;
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;

		> * {
			position: absolute;
			width: calc(100vh * 1.78) !important;
			height: 100% !important;
			top: 0;
			left: calc((100vw - (100vh * 1.78)) * 0.5);
		}
	}

	header {
		position: fixed;
		left: 0;
		right: 0;
		z-index: 499;
	}

	main {
		margin-top: 120px;

		@media only screen and (max-width: 1150px) {
			& {
				margin-top: 90px;
			}
		}
	}

	& a.external-link {
		padding: 5px 20px;
		font-weight: bold;
		font-size: 18px;
		background-color: var(--yellow);
		color: var(--dark-blue);
		white-space: nowrap;
		border-radius: 5px;
		text-decoration: none;
	}
}
</style>
