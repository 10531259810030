<template lang="pug">
	main.home
		section.hero#hero
			.text-container
				h1 FIRST LEARN, THEN REMOVE “L”
				h2 Join us today!
			.animation
				Lottie(path="animations/banner.json")
		section.info#forex
			.product
				img(src="@/assets/img/product_forex.svg" alt="Forex")
				h1 FOREX
				p Η λέξη forex προκύπτει από τα αρχικά των λέξεων foreign exchange, και σημαίνει ξένο συνάλλαγμα. Είναι μια παγκόσμια χρηματοπιστωτική αγορά όπου συναλλάσσονται όλα τα νομίσματα του κόσμου και διαθέτει την μεγαλύτερη ρευστότητα με μέσο όρο ημερήσιου όγκου συναλλαγών άνω των 6 τρις δολαρίων. Αυτό σημαίνει πως διαθέτει καθημερινά πολλές και καλές επενδυτικές ευκαιρίες που πιθανόν να μην υπάρχουν σε άλλον τομέα-κλάδο επενδύσεων.
			.product
				img(src="@/assets/img/product_cfd.svg" alt="CFD")
				h1 CFD’s
				p Εκτός των νομισμάτων, στην αγορά του forex μπορούμε να κάνουμε συναλλαγές και με CFD’s (Contracts for Difference). Tα CFD’s είναι παράγωγα επενδυτικά προϊόντα με τα οποία κάνουμε συναλλαγές επί της κίνησης της τιμής διαφόρων χρηματοοικονομικών αγορών όπως είναι οι δείκτες, η ενέργεια, τα μέταλλα και οι όροι συμβολαίων.
			.product
				img(src="@/assets/img/product_stocks.svg" alt="Stocks")
				h1 STOCKS
				p Τα stocks ή αλλιώς μετοχές είναι μερίδια ιδιοκτησίας σε μια επιχείρηση-εταιρεία. Μέσω μιας μετοχής η επιχείρηση-εταιρεία δίνει την δυνατότητα συμμετοχής του επενδυτικού  κοινού στο κεφάλαιό της και καταφέρνει να αντλεί τα απαραίτητα για επενδύσεις κεφάλαια. Οι τιμή μιας μετοχής ποικίλλει και αλλάζει συνεχώς ανάλογα με την προσφορά και την ζήτηση.
			.product
				img(src="@/assets/img/product_crypto.svg" alt="Crypto")
				h1 CRYPTO
				p Κρυπτονόμισμα ονομάζεται κάθε μορφή ψηφιακού χρήματος που δεν εκδίδεται από κάποια τράπεζα ή κυβέρνηση και υπάρχει μόνο ηλεκτρονικά-ψηφιακά στο διαδίκτυο, προστατεύεται μέσω της κρυπτογραφίας και μπορεί να μεταφερθεί ψηφιακά. Το πρώτο κρυπτονόμισμα που δημιουργήθηκε είναι το Bitcoin με έτος δημιουργίας το 2009 και από τότε έχουν δημιουργηθεί χιλιάδες ακόμα κρυπτονομίσματα.
		section.programs#programs
			h1 ΤΑ ΠΡΟΓΡΑΜΜΑΤΑ ΣΠΟΥΔΩΝ - COURSES
			.program-selector
				.blocks
					img(src="@/assets/img/box-03.svg" alt="Program 03" @mouseover="programHover('course-fundamental')")
					img(src="@/assets/img/box-02.svg" alt="Program 02" @mouseover="programHover('course-advance')")
					img(src="@/assets/img/box-01.svg" alt="Program 01" @mouseover="programHover('course-basics')")
				.courses
					.course#course-basics.active
						.course-header
							h2 01
							h3 FOREX BASIS (2 ΜΗΝΕΣ)
						ul
							li Θεωρία του forex και των cfd’s
							li Τι είναι pip, lot size, leverage, margin
							li Τεχνική ανάλυση 6 σημείων (support, resistance, trends, zones κ.α)
							li Japanese candle sticks
							li Λεξιλόγιο βασικών όρων της forex αγοράς όπως pullback, retest, volatility κ.α.
							li Βασικά patterns που εμφανίζονται στην αγορά (flag, double top+bottom, Head & Shoulder, Elliott Wave κ.α)
							li Εκμάθηση 6 βασικών indicators (Μ.Α, ΕΜΑ, Macd κ.α)
							li Fibonacci retracement
							li Risk management
							li Άνοιγμα demo λογαριασμού
						.course-header
							h2 02
							h3 FOREX ADVANCE (2 ΜΗΝΕΣ)
						ul
							li Advance patterns που εμφανίζονται στην αγορά
							li Advance Japanese candle sticks
							li Επιπλέον τεχνική ανάλυση 6 σημείων
							li Αναλυτική επεξήγηση οικονομικών όρων όπως CPI, PPI,HPI κ.α.
							li Εκμάθηση 4 στρατηγικών και μεθοδολογιών (scalping, swing trading)
							li Οικονομικοί δείκτες χωρών
							li Αναλυτική επεξήγηση και ανάλυση χρυσού, ασημιού, πετρελαίου
							li Ανάλυση καθημερινών νέων-ειδήσεων
							li Ανάλυση ειδησεογραφικών άρθρων
							li Διατήρηση demo λογαριασμού για εξάσκηση-πρακτική
					.course#course-advance
						.course-header
							h2 03
							h3 FUNDAMENTAL ANALYSIS (2 ΜΗΝΕΣ)
						ul
							li Οικονομικοί κύκλοι
							li Πλήρης ανάλυση των φαινομένων ανεργία, κρίση, πόλεμος κ.α.
							li Ομιλίες προέδρων – τι να προσέχεις και πως να τις χρησιμοποιείς
							li Νομισματικές πολιτικές κεντρικών τραπεζών και stimulus programs
							li Παράγωγα και αμοιβαία κεφάλαια
							li Ανάλυση οικονομικών αναλυτών
							li Κορυφαίοι οικονομικοί δείκτες και projections κεντρικών τραπεζών
							li Μακροοικονομία + Μικροοικονομία
							li Χρυσός χρονική ανάλυση και πως να τον χρησιμοποιείς
							li Legislation + Regulators
						.course-header
							h2 04
							h3 SENTIMEN ANALYSIS (3 ΜΗΝΕΣ)
						ul
							li Θεωρία και κατανόηση της sentiment alanalysis, τι επιρροές έχει
							li Δείκτες μέτρησης sentiment και μεταβλητότητας
							li Δείκτης sentiment ISEE
							li Καταναλωτικό συναίσθημα
							li Περιβάλλον μακροεντολών
							li Παράγωγα και πως συνδέονται με τον δείκτη VIX
							li Συναισθήματα και δράσεις μιας επενδυτικής κοινότητας
							li Διαπραγματεύσεις στα διεθνή χρηματιστήρια, διαμόρφωση της ψυχολογίας και της συμπεριφοράς των επενδυτών
							li Χρηματιστηριακές φούσκες και κρίσεις
							li Sentiment και τεχνική ανάλυση συνδυαστικά με fundamental ανάλυση κανόνες παρατηρητικότητας
					.course#course-fundamental
						.course-header
							h2 05
							h3 CRYPTO (1 ΜΗΝΑΣ)
						ul
							li Εισαγωγή στα κρυπτονομίσματα, ιστορία, δημιουργία κ.α.
							li Τεχνολογία blockchain
							li Wallets και ανταλλακτήρια, όσα πρέπει να ξέρεις
							li Τεχνική ανάλυση κρυπτονομισμάτων
							li Altcoins, CryptoAssets, Cryptotokens
							li Επιλογή της κατάλληλης πλατφόρμας για αγορά crypto και ακριβής οδηγός εγγραφής
							li Προτάσεις για αγορά κρυπτονομισμάτων
							li Ασφάλεια, κίνδυνοι και νομοθεσία
						.course-header
							h2 06
							h3 STOCKS (2 ΜΗΝΕΣ)
						ul
							li Εισαγωγή στην χρηματοοικονομική ανάλυση, είδη μετοχών, είδη χρηματιστηρίων και η ιστορία τους 
							li Ελληνικό + ξένο χρηματιστήριο
							li Ανάλυση δεικτών του κάθε χρηματιστηρίου 
							li Οικονομικοί κύκλοι, trends, μέθοδοι επένδυσης
							li Μερίσματα, splits, IPOs
							li Etfs, options, futures, bonds
							li Τεχνική ανάλυση μετοχών 
							li Στρατηγικές αγοράς μετοχών 
							li Άνοιγμα demo λογαριασμού 
							li Επιλογή της σωστής πλατφόρμας για αγορά μετοχών 
							li Ασφάλεια, κίνδυνοι και νομοθεσία
		section.tips
			.suggestion
				.header 
					h1 Τι προτείνουμε εμείς;
					img(src="@/assets/img/bg-graph.svg" alt="Background Graph")
				p 1. Αν είσαι αρχάριος, ξεκίνα από το μηδέν ώστε να αποκτήσεις τις πιο γερές βάσεις πάνω στην forex αγορά και το trading. Διάλεξε το 4μηνο course με <b>2 MHNEΣ FOREX BASICS</b> και <b>2 ΜΗΝΕΣ FOREX ADVANCE</b>. Με το πέρας του 4μηνου course θα μπορείς να δρας στην αγορά του forex 100% αυτόνομα με σιγουριά και αυτοπεποίθηση.
					| <br><br>
					| 2. Αν είσαι έμπειρος trader, με γνώσεις τεχνικής ανάλυσης και θεωρίας, διαθέτεις τουλάχιστον 3 μήνες demo λογαριασμό με σωστό-ρεαλιστικό capital και σωστό risk management τότε το καλύτερο course για εσένα είναι το <b>FOREX ADVANCE</b> διάρκειας 2 μηνών. Μεταξύ άλλων θα εμπλουτίσεις τις γνώσεις σου πάνω στην τεχνική ανάλυση, θα διδαχθείς 4 στρατηγικές και αναλύσεις και θα μάθεις πώς τα νέα μπορούν να επηρεάσουν την αγορά και πως να τα εκμεταλλευτείς.
					| <br><br>
					| 3. Αν είσαι έμπειρος και ενεργός trader με real λογαριασμό και δική σου στρατηγική και θες να εμπλουτίσεις τις γνώσεις σου πάνω στην βαθύτερη ουσία της οικονομίας τότε δεν έχεις καλύτερη επιλογή από το να διαλέξεις το 3μηνο course της <b>FUNDAMENTAL ANALYSIS</b>.
					| <br><br>
					| 4. Αν έχεις τελειοποιήσει τις θεωρητικές και πρακτικές σου γνώσεις πάνω στην τεχνική και την fundamental ανάλυση, είσαι ενεργός trader για αρκετό χρονικό διάστημα με real λογαριασμό (τουλάχιστον 6 μήνες) και θες να εξελιχθείς στον χώρο, τότε δεν έχεις παρά να επιλέξεις το course της <b>SENTIMENT ANALYSIS</b> ώστε να κατανοήσεις πλήρως το γενικότερο αίσθημα που επικρατεί στην αγορά, πως επηρεάζει τις αγορές και πως μπορείς να το εκμεταλλευτείς.
					| <br><br>
					| 5. Αν σε ενδιαφέρει η μακροπρόθεσμη επένδυση και τα τελευταία χρόνια ακούς και διαβάζεις παντού για "το χρήμα του μέλλοντος" τότε μπορείς να επιλέξεις το course των <b>CRYPTO</b> και χωρίς καμία προηγούμενη γνώση, ξεκινώντας δηλαδή από το μηδέν, σε μόλις έναν μήνα να μυηθείς στον χώρο των κρυπτονομισμάτων και να γίνεις κάτοχος πολλών από των πιο γνωστών και μη κρυπτονομισμάτων.
					| <br><br>
					| 6. Τέλος, παραμένοντας στο κομμάτι της μακροπρόθεσμης επένδυσης, κανείς δεν μπορεί να αγνοήσει τον γνωστό σε όλους "γίγαντα των επενδύσεων" χώρο, που δεν είναι άλλος από αυτόν των μετοχών. Αν θέλεις λοιπόν να φτιάξεις το δικό σου portfolio μετοχών σε ελληνικό και ξένο χρηματιστήριο δεν έχεις παρά να επιλέξεις το course των <b>STOCKS</b> και μέσα σε μόλις δύο μήνες να γνωρίσεις τα πάντα για τις πιο μεγάλες εταιρείες παγκοσμίως και να επενδύσεις σε αυτές.
			.fit
				.text
					label Δεν σου ταιριάζει πλήρως κάποιο από τα courses που προτείνουμε;
					p Κλείσε ένα online ραντεβού να μιλήσουμε για το επίπεδο των γνώσεων σου, παρουσίασέ μας τι θες να πετύχεις και μέχρι που θες να φτάσεις τις γνώσεις σου και θα φτιάξουμε παρέα ένα custom made course ακριβώς στα μέτρα σου.
				a.external-link(href='https://calendly.com/athensfxacademy' target="_blank") Κλείσε ραντεβού
		section.deal
			//- .bubble
			h1 EXTRA ΔΩΡΕΑΝ BONUS όλων των courses
			p Όποιο course και να διαλέξεις, θα έχεις την δυνατότητα να παρακολουθήσεις εβδομαδιαίως, εντελώς <b>ΔΩΡΕΑΝ</b>, άλλα 4 μαθήματα που έχουν άμεση σχέση με τον χώρο του trading και των επενδύσεων και τα θεωρούμε απαραίτητα.
		section.team#scroller
			h1 Η ομαδα
			.members
				.member
					h2 Ψυχολογία και trading
					h3 Διδάσκεται από μέλος της εκπαιδευτικής μας ομάδας
					p Εσύ το ήξερες πως για να θεωρείσαι πετυχημένος trader και επενδυτής πρέπει να έχεις κατά 80% καλή και σωστή ψυχολογία; Όχι; Για αυτόν τον λόγο προσθέσαμε στο πρόγραμμα σπουδών μας το μάθημα της ψυχολογίας και trading για να κατανοήσει και να μάθει ο κάθε μαθητής μας πως να έχει την σωστή ψυχολογία πριν μπει στην αγορά, τι είναι αυτό που μπορεί να του "χαλάσει" την ψυχολογία και φυσικά πως να διαχειρίζεται τα συναισθήματα του.
				.member
					h2 Personal development
					h3 Διδάσκεται από μέλος της εκπαιδευτικής μας ομάδας
					p Ένας σωστός και σταθερός χαρακτήρας, με αυτοπεποίθηση και στόχους μπορεί να πάει μπροστά σε όλους τους τομείς της ζωής του, όπως και στην αγορά του forex αλλά και των επενδύσεων. Μέσω αυτού του μαθήματος και της ομαδικής αλλά και προσωπικής συζήτησης που γίνεται με τον εκπαιδευτή, θα μπορέσεις να πετύχεις πολλά από τα παραπάνω και να ανακαλύψεις ποια είναι τα στοιχεία τα οποία πρέπει να αποβάλλεις από πάνω σου, ποια καινούρια θα πρέπει να υιοθετήσεις ώστε να κάνεις αυτό το νέο και μεγάλο βήμα που θα σε φέρει πιο κοντά στην προσωπική σου εξέλιξη.
			.members
				.member
					h2 Risk management
					h3 Διδάσκεται από μέλος της εκπαιδευτικής μας ομάδας
					p Κανένας trader ή επενδυτής δεν θα μπορούσε να επιβιώσει στην αγορά του forex και στην αγορά των επενδύσεων χωρίς την ύπαρξη του risk management. Το risk management είναι ο τρόπος να κρατήσεις τον λογαριασμό σου και το κεφάλαιο σου ασφαλή, να μην το θέτεις σε κίνδυνο και να καταφέρνεις να έχεις πάντα κέρδος στις συναλλαγές σου ή την μικρότερη δυνατή ζημιά. Είναι το Α και το Ω για έναν trader και επενδυτή αρχάριο και μη.
				.member
					h2 Weekly outlook
					h3 Διδάσκεται από μέλος της εκπαιδευτικής μας ομάδας
					p Εβδομαδιαία ομαδική κλήση στην αρχή της εβδομάδας που απευθύνεται σε όλα τα μέλη και τους μαθητές, ώστε να δουν μαζί με τους εκπαιδευτές τους την αγορά για την εβδομάδα που επρόκειτο να έρθει και τις κινήσεις που αναμένονται στα πιο hot νομισματικά ζευγάρια και cfd’s. Γίνεται αναλυτική τεχνική ανάλυση πάνω στα ζευγάρια, λύνονται απορίες και ο κάθε μαθητής και μέλος λέει την γνώμη του και αυτό που περιμένει από την αγορά με βάση την δική του τεχνική ανάλυση.
		section.appointment
			h2 Μην χάνεις χρόνο...<br>Προγραμμάτισε μία συνάντηση τώρα!
			a.external-link(href='https://calendly.com/athensfxacademy' target="_blank") Κλείσε ραντεβού
		section.contact#contact
			h2 Επικοινωνήσε μαζί μας
			.form-container
				p Για οποιαδήποτε απορία συμπλήρωσε την φόρμα και επικοινώνησε μαζί μας
				.form

</template>

<script>
import Lottie from 'lottie-vuejs/src/LottieAnimation.vue'

export default {
	components: {
		Lottie
	},
	methods: {
		programHover: function(box) {
			document
				.querySelectorAll('section#programs .courses > *')
				.forEach((element) => element.classList.remove('active'))
			document.querySelector(`#${box}`).classList.add('active')
		}
	},
	mounted() {
		const script = document.createElement('script')
		script.src = '//js.hsforms.net/forms/shell.js'
		document.body.appendChild(script)
		script.addEventListener('load', () => {
			if (window.hbspt) {
				window.hbspt.forms.create({
					region: 'na1',
					portalId: '20113661',
					formId: '09dbca74-a56c-4adf-a28d-095fe47ce3be'
				})
				document
					.querySelector('section.contact .form')
					.append(document.querySelector('.hbspt-form'))
			}
		})
	}
}
</script>

<style lang="scss" scoped>
main {
	display: flex;
	flex-direction: column;

	section {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	}

	.hero {
		height: 30vh;
		padding: 20px;
		background-color: var(--dark-blue);
		overflow: hidden;
		position: relative;
		align-items: center;

		@media only screen and (max-width: 850px) {
			& {
				height: 45vh;

				.animation {
					width: 150% !important;
					height: auto !important;
					bottom: -10vw;
					top: unset !important;
					left: 0 !important;
				}

				.text-container {
					width: unset !important;
					top: 0 !important;
					padding: 0 10px;
					right: unset !important;
				}
			}
		}

		.animation {
			position: absolute;
			top: -25vh;
			left: 20;
			height: 75vh;
			width: calc(75vh * 1.77);
		}

		.text-container {
			position: absolute;
			color: var(--white);
			font-weight: bold;
			font-size: 25px;
			display: flex;
			flex-direction: column;
			left: unset;
			right: 20px;
			top: 20%;
			width: 45%;
			z-index: 1;

			> * {
				margin: unset;

				&:last-child {
					font-size: 25px;
				}
			}
		}
	}

	.info {
		padding: 30px 0;
		flex-direction: row;
		align-items: stretch;
		justify-content: center;
		color: white;
		-ms-overflow-style: none;
		scrollbar-width: none;
		&::-webkit-scrollbar {
			width: 0; /* Remove scrollbar space */
			background: transparent; /* Optional: just make scrollbar invisible */
		}

		> * {
			margin-right: 25px;

			&:last-child {
				margin-right: unset;
			}
		}

		.product {
			display: flex;
			flex-direction: column;
			align-items: center;
			background-color: var(--light-blue);
			border-radius: 10px;
			padding: 20px 15px;
			box-shadow: 5px 5px 6px #08141c4d;
			transition: all 0.4s ease;

			&:hover {
				transform: scale(1.05);
			}

			h1 {
				font-weight: bold;
				font-size: 20px;
			}

			p {
				font-size: 14px;
				margin: unset;
				width: 250px;
			}
		}

		@media only screen and (max-width: 1300px) {
			& {
				padding: 30px 40px;
				overflow-x: scroll;
				justify-content: flex-start;

				> *:last-child {
					margin-right: 25px;
				}
			}
		}
	}

	.programs {
		// background: linear-gradient(
		// 	0deg,
		// 	#091b2d 0%,
		// 	#0d223b 6%,
		// 	#1d3d70 29%,
		// 	#2a539a 51%,
		// 	#3463b8 71%,
		// 	#396cca 88%,
		// 	#3c70d1 100%
		// );
		justify-content: center;
		align-items: center;
		padding: 30px;
		text-align: center;

		> h1 {
			font-weight: bold;
			font-size: 20px;
		}

		.program-selector {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-evenly;
			width: 100%;
			align-items: center;

			> * {
				width: 45%;
			}

			@media only screen and (max-width: 800px) {
				justify-content: center;

				> * {
					height: unset !important;
					width: unset;
				}
			}

			.blocks {
				height: 700px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				@for $i from 0 to 3 {
					:nth-child(#{$i + 1}) {
						bottom: 0;
						z-index: 3-$i;
						width: calc(450px + (#{$i - 1} * 100px));

						@media only screen and (max-width: 1200px) {
							width: calc(250px + (#{$i - 1} * 50px));
						}
					}
				}

				:nth-child(1) {
					margin-bottom: -200px;
				}

				:nth-child(2) {
					margin-bottom: -250px;
				}

				@media only screen and (max-width: 1200px) {
					:nth-child(1) {
						margin-bottom: -100px;
					}

					:nth-child(2) {
						margin-bottom: -125px;
					}
				}
			}

			.courses {
				background-color: var(--light-blue);
				display: flex;
				flex-wrap: wrap;
				color: white;
				justify-content: center;
				// margin-right: -25px;
				// margin-bottom: -25px;
				position: relative;
				box-shadow: 15px 15px 0px #224bd826;

				@media only screen and (max-width: 400px) {
					& {
						margin-right: unset;

						.course {
							// width: 225px !important;
							margin-right: unset !important;
						}
					}
				}

				@for $i from 1 to 4 {
					:nth-child(#{$i}) {
						@for $x from 0 to 2 {
							& .course-header:nth-of-type(#{$x + 1}) h2::before {
								content: '0' + #{($i * 2) - 1 + $x};
								color: var(--dark-blue);
								z-index: -1;
								opacity: 0.3;
								position: absolute;
								top: 0;
								left: 3px;
							}
						}
					}
				}

				.course {
					display: none;
					// align-items: center;
					padding: 25px;
					flex-direction: column;
					border-radius: 10px;
					margin-right: 25px;
					margin-bottom: 25px;

					&.active {
						display: flex;
					}

					.course-header {
						display: flex;
						align-items: baseline;
						flex-wrap: wrap;

						h2,
						h3 {
							margin: 0;
							color: var(--yellow);
						}

						h2 {
							font-weight: bold;
							padding-right: 10px;
							font-size: 40px;
							position: relative;
							z-index: 1;
						}

						h3 {
							font-size: 20px;
						}
					}

					ul {
						padding-inline-start: 25px;

						li {
							font-size: 18px;
							text-align: left;
						}
					}
				}
			}
		}
	}

	.tips {
		position: relative;
		font-size: 16px;
		font-weight: bold;

		// &::before {
		// 	content: '';
		// 	position: absolute;
		// 	top: 0;
		// 	left: 0;
		// 	width: 100%;
		// 	height: 100%;

		// 	background-color: #091b2dc0;
		// }

		@media only screen and (max-width: 500px) {
			background-size: auto 100%;

			> * {
				padding: 20px !important;
			}
		}

		> * {
			z-index: 2;
			padding: 20px 10%;

			@media only screen and (max-width: 1150px) {
				& {
					padding: 20px 40px;
				}
			}
		}

		.suggestion {
			display: flex;
			justify-content: flex-end;

			> * {
				margin: unset;
			}

			.header {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 40%;
				margin-right: 30px;

				h1 {
					text-align: right;
					font-weight: bold;
					font-size: 30px;
				}

				img {
					margin: 10px 0;
					width: 100%;
					margin-top: auto;
				}
			}

			p {
				font-weight: 600;
				width: 60%;
			}

			@media only screen and (max-width: 1150px) {
				& {
					flex-direction: column;
					justify-content: center;

					.header,
					p {
						width: unset;

						img {
							width: 60%;
						}
					}

					.header h1 {
						text-align: center;
					}
				}
			}
		}

		.fit {
			display: flex;
			justify-content: center;
			align-items: center;

			.text {
				display: flex;
				flex-direction: column;
				margin-right: 20px;
				font-weight: 600;

				label {
					font-weight: bold;
					font-size: 20px;
				}

				p {
					margin: unset;
				}
			}

			@media only screen and (max-width: 650px) {
				& {
					flex-direction: column;

					.text {
						margin-right: unset;
						margin-bottom: 20px;
					}
				}
			}
		}
	}

	.deal {
		padding: 20px 10%;
		background-color: var(--light-blue);
		justify-content: center;
		align-items: center;
		position: relative;
		text-align: center;
		color: white;
		font-weight: 600;

		@media only screen and (min-width: 800px) {
			&::before {
				content: '';
				position: absolute;
				background-size: 100%;
				top: 0;
				left: 0;
				height: 100%;
				width: 100px;
				background-image: url('../assets/img/bg-left.svg');
			}

			&::after {
				content: '';
				position: absolute;
				background-size: 100%;
				top: 0;
				right: 0;
				height: 100%;
				width: 100px;
				background-image: url('../assets/img/bg-right.svg');
			}
		}

		@media only screen and (max-width: 800px) {
			& {
				padding: 10px;

				.bubble {
					padding: 20px !important;
				}
			}
		}

		.bubble {
			background-color: white;
			font-size: 16px;
			padding: 10px 75px;
			box-shadow: 8px 8px 0px #00000020;

			h1 {
				font-weight: bold;
				font-size: 20px;
				text-align: center;
			}
		}
	}

	.team {
		padding: 20px 10%;
		align-items: center;

		> h1 {
			font-weight: bold;
			font-size: 25px;
			text-transform: uppercase;
		}

		@media only screen and (max-width: 1000px) {
			padding: 20px;
		}

		@media only screen and (max-width: 500px) {
			padding: 10px;
		}

		.members {
			display: flex;
			align-items: stretch;

			@media only screen and (max-width: 700px) {
				& {
					flex-direction: column;
					align-items: center;

					.member {
						background-color: #1a50b7;
						color: var(--white);
						padding: 15px 10px;
						align-items: center !important;
						text-align: left !important;
						margin-right: unset !important;
						box-shadow: 1px 1px 3px #08141c4d;
						width: unset !important;

						h2,
						h3 {
							text-align: center;
						}
					}
				}
			}

			.member {
				display: flex;
				flex-direction: column;
				margin-bottom: 20px;
				border-radius: 10px;
				width: 50%;

				&:first-child {
					align-items: flex-end;
					text-align: right;
					margin-right: 20px;
				}

				> * {
					margin: unset;
				}

				h2 {
					font-size: 20px;
				}

				h3 {
					font-weight: bold;
					font-size: 14px;
				}

				p {
					margin-top: 10px;
					font-size: 14px;
				}
			}
		}
	}

	.appointment {
		flex-direction: row;
		justify-content: center;
		align-items: center;
		background-color: var(--dark-blue);
		font-weight: bold;
		font-size: 16px;
		color: var(--white);

		@media only screen and (max-width: 800px) {
			& {
				flex-direction: column;
				padding: 20px 10px;

				> * {
					width: unset !important;
					margin: unset;
					margin-bottom: 20px;
				}
			}
		}

		h2 {
			margin-right: 20px;
			width: 400px;
		}
	}

	.contact {
		align-items: center;
		padding: 20px 10px;

		.form-container {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-evenly;

			p {
				font-weight: bold;
				text-align: center;
				width: calc(50% - 50px);
				height: 100%;
			}

			.form {
				box-shadow: 0px 0px 8px #00000033;
				border-radius: 13px;
				padding: 20px;
			}
		}
	}
}
</style>
