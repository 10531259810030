<template lang="pug">
header
	router-link.logo(:to='{ name: "Home" }' v-on:click.native="scroll()")
		img(src='@/assets/img/logo.svg', alt='Logo')
	.navigation-bar
		router-link(:to='{ name: "Home" }' v-on:click.native="scroll('forex')") Forex
		router-link(:to='{ name: "Home" }' v-on:click.native="scroll('programs')") Πρόγραμμα
		router-link(:to='{ name: "About" }') Ποιοι είμαστε
		router-link(:to='{ name: "FAQ" }') Συχνές ερωτήσεις
		router-link(:to='{ name: "Home" }' v-on:click.native="scroll('contact')") Επικοινωνία
		a.external-link(href='https://calendly.com/athensfxacademy' target="_blank") Κλείσε ραντεβού
	.menuToggle
		input(type='checkbox')
		span
		span
		span
		.menu
			router-link(v-on:click.native="closeMenu($event); scroll('forex')" :to='{ name: "Home" }') Forex
			router-link(v-on:click.native="closeMenu($event); scroll('programs')" :to='{ name: "Home" }') Πρόγραμμα
			router-link(v-on:click.native="closeMenu" :to='{ name: "About" }') Ποιοι είμαστε
			router-link(v-on:click.native="closeMenu" :to='{ name: "FAQ" }') Συχνές ερωτήσεις
			router-link(v-on:click.native="closeMenu($event); scroll('contact')" :to='{ name: "Home" }') Επικοινωνία
			a.external-link(href='https://calendly.com/athensfxacademy' target="_blank" v-on:click="closeMenu") Κλείσε ραντεβού
</template>

<script>
export default {
	methods: {
		closeMenu: () => {
			const menu = document.querySelector('header .menuToggle')
			menu.querySelector('input').checked = false
		},
		scroll(id) {
			if (id) {
				const y =
					document.getElementById(id).getBoundingClientRect().top +
					window.scrollY
				window.scroll({
					top: y - 120,
					behavior: 'smooth'
				})
			} else {
				document.body.scrollIntoView({ behavior: 'smooth' })
			}
		}
	}
}
</script>

<style lang="scss" scoped>
header {
	height: 100px;
	background-color: var(--dark-blue);
	display: flex;
	padding: 10px 10%;
	font-weight: 600;
	font-size: 18px;
	color: var(--white);

	@media only screen and (max-width: 1150px) {
		& {
			padding: 20px 40px;
			height: 50px;

			.navigation-bar {
				display: none !important;
			}

			.menuToggle {
				display: flex !important;
			}
		}
	}

	.logo {
		display: flex;
		margin-right: auto;
		z-index: 603;

		* {
			height: 100%;
		}
	}

	.navigation-bar,
	.menuToggle .menu {
		a {
			color: inherit;
			text-decoration: none;
			white-space: nowrap;
			padding: 5px 5px;
		}
	}

	.navigation-bar {
		display: flex;
		align-items: flex-end;
		margin-bottom: 10px;

		> * {
			margin-right: 10px;

			&:last-child {
				margin-right: unset;
			}
		}
	}

	.menuToggle {
		margin-left: auto;
		display: none;
		position: relative;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		-webkit-user-select: none;
		user-select: none;

		input {
			display: block;
			width: 40px;
			height: 32px;
			position: absolute;
			top: calc(50% - 20px);
			left: -5px;

			cursor: pointer;

			opacity: 0; /* hide this */
			z-index: 604; /* and place it over the hamburger */

			-webkit-touch-callout: none;

			&:checked ~ span {
				opacity: 1;
				transform: rotate(45deg) translate(-2px, -1px);
				background: white;

				&:nth-last-child(3) {
					opacity: 0;
					transform: rotate(0deg) scale(0.2, 0.2);
				}

				&:nth-last-child(2) {
					transform: rotate(-45deg) translate(0, -1px);
				}
			}

			&:checked ~ .menu {
				transform: none;
			}
		}

		span {
			display: block;
			width: 33px;
			height: 4px;
			margin-bottom: 5px;
			position: relative;

			background: white;
			border-radius: 3px;

			z-index: 603;

			transform-origin: 4px 0px;

			transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
				background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
				opacity 0.55s ease;

			&:first-child {
				transform-origin: 0% 0%;
			}

			&:nth-last-child(2) {
				transform-origin: 0% 100%;
			}
		}

		.menu {
			top: 0;
			left: 0;
			position: fixed;
			width: 100vw;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: flex-start;
			text-align: center;
			margin: auto 0;
			padding: 50px 0;
			padding-top: 125px;

			z-index: 600;

			background: var(--dark-blue);
			list-style-type: none;
			-webkit-font-smoothing: antialiased;

			transform-origin: 0% 0%;
			transform: translate(0, -100%);

			transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

			a,
			.nav-link {
				text-decoration: none;
				color: inherit;
				margin-bottom: 10px;

				&.external-link {
					margin-top: 20px;
				}
			}
		}
	}
}
</style>
